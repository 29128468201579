import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import DownloadComponent from "../components/manualDownloadComponents/DownloadComponent"

import MicroMagImage from "../components/individualProductPage/images/micromag-20d-pa-360s-view-1.jpg"
import MicroMiniImage from "../components/individualProductPage/images/micromini-20d-360-view-1.jpg"
import MicroHDImage from "../components/individualProductPage/images/micro-hd-25c-floor-360s-view-1.jpg"
import MiniHDImage from "../images/mini-hd-25c_0001_mini-hd-25c-1.6.png"
import MagHDImage from "../components/individualProductPage/images/mag-hd-29c-360-view-1.jpg"
import PilotImage from "../images/pilot-29C-1.jpg"
import GTXImage from "../images/gtx-33c-1.jpg"
import XRImage from "../images/xr-46c-1.jpg"
import GTRImage from "../images/gtr-37C-1.jpg"
import Sweeper34Image from "../components/individualProductPage/images/34-1.jpg"
import TrImage from "../components/individualProductPage/images/tr-sweeper-1.jpg"
import DownloadCard from "../components/manualDownloadComponents/DownloadCard"

const PageLayout = styled.div`
  max-width: 1400px;
  /* height: 580px; */
  margin: 0 auto;
  /* border: 1px solid black; */
`
const CardLayout = styled.div`
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  @media (max-width: 1150px) {
    grid-template-columns: 50% 50%;
  }
  @media (max-width: 650px) {
    grid-template-columns: 100%;
  }
`

const H1 = styled.h1`
  width: 300px;
  border-bottom: 2px solid red;
  font-size: 1.5em;
  padding: 0.5rem;
`
const Select = styled.select`
  padding: 0.5rem;
  font-size: 1.2em;
  border-radius: 7px;
  border: 1px solid black;
`

const ManualDownloads = () => {
  const [selectValue, setSelectValue] = useState("")
  //handle selected value
  const handleSelect = e => {
    setSelectValue(e.target.value)
  }
  return (
    <Layout>
      <SEO
        title="manual-downloads"
        description="Manual downloads for FactoryCat scrubbers and sweepers"
      />
      <PageLayout>
        <H1>Manual Downloads</H1>
        {/* <Select value={selectValue} onChange={handleSelect}>
          <option>Choose your scrubber or sweeper</option>
          <optgroup label="Walk Behind Floor Scrubbers">
            <option value="micromag">MicroMag</option>
            <option value="micromini">MicroMini</option>
            <option value="microhd">Micro-HD</option>
            <option value="minihd">Mini-HD</option>
            <option value="maghd">Mag-HD</option>
          </optgroup>
          <optgroup label="Ride On Floor Scrubbers">
            <option value="pilot">Pilot</option>
            <option value="gtx">GTX</option>
            <option value="gtr">GTR</option>
            <option value="xr">XR</option>
          </optgroup>
          <optgroup label="Sweepers">
            <option value="34">34</option>
            <option value="tr">TR</option>
          </optgroup>
        </Select> */}
        <Link to="/serial" style={{ color: "black", textDecoration: "none" }}>
          <p style={{ fontSize: "1.3em" }}>
            Find manuals by serial number{" "}
            <i className="fas fa-arrow-circle-right" />
          </p>
        </Link>
        <CardLayout>
          <DownloadCard
            name="MicroMag"
            image={MicroMagImage}
            alt="FactoryCat - MicroMag Walk behind floor scrubber"
            judge="/static/FC-WALKBEHIND-YBTJ-0e9a00af72bba89988e8cd60c17d5f3d.pdf"
            brochure="https://www.dropbox.com/s/hy2n523tmja4qk4/FC-MICROMAG-BROCHURE.pdf?dl=0"
            techSpecs="https://www.factorycat.com/docs/techSpecs/FC-MICROMAG-SALESSHEET.pdf"
            partsManual="https://www.dropbox.com/s/n7oxil94ugm7zqx/FC-MAG-HD-P-EN.pdf?dl=0"
            opsManual="https://www.factorycat.com/docs/manualsOp/FC-MICROMAG-OP-EN.pdf"
          />
          <DownloadCard
            name="MicroMini"
            image={MicroMiniImage}
            alt="FactoryCat - MicroMag Walk behind floor scrubber"
            judge="/static/FC-WALKBEHIND-YBTJ-0e9a00af72bba89988e8cd60c17d5f3d.pdf"
            brochure="https://www.dropbox.com/s/sx3o4ru9okum420/FC-MICROMINI-BROCHURE.pdf?dl=0"
            techSpecs="https://www.factorycat.com/docs/techSpecs/FC-MICROMINI-SALESSHEET.pdf"
            partsManual="https://www.dropbox.com/s/xfbapwn8e919bna/MICROMINI-P-EN.pdf?dl=0"
            opsManual="https://www.dropbox.com/s/00ysgqj35i2yoii/MICROMINI-OP-EN.pdf?dl=0"
          />
          <DownloadCard
            name="Micro-HD"
            image={MicroHDImage}
            judge="/v2-you-be-the-judge.pdf"
            alt="FactoryCat - MicroMag Walk behind floor scrubber"
            brochure="https://www.dropbox.com/s/isekh8ont113wxr/FC-V2-WALK-BEHIND-SM.pdf?dl=0"
            techSpecs="/factorycat-micro-mini-mag-techspecs.pdf"
            partsManual="https://www.dropbox.com/s/wkp5415dkwdwl05/FC-MICRO-HD-v2-P-EN.pdf?dl=0"
            opsManual="https://www.dropbox.com/s/mnz7nzrpr9fa9bb/FC-walk-behind-v2-OP-EN.pdf?dl=0?dl=0"
          />
          <DownloadCard
            name="Mini-HD"
            image={MiniHDImage}
            alt="FactoryCat - Mini-HD Walk behind floor scrubber"
            judge="/v2-you-be-the-judge.pdf"
            brochure="https://www.dropbox.com/s/isekh8ont113wxr/FC-V2-WALK-BEHIND-SM.pdf?dl=0"
            techSpecs="/factorycat-micro-mini-mag-techspecs.pdf"
            partsManual="https://www.dropbox.com/s/j08z9mxcolidnmb/FC-MINI-HD-v2-P-EN.pdf?dl=0"
            opsManual="https://www.dropbox.com/s/mnz7nzrpr9fa9bb/FC-walk-behind-v2-OP-EN.pdf?dl=0?dl=0"
          />
          <DownloadCard
            name="Mag-HD"
            image={MagHDImage}
            alt="FactoryCat - Mag-HD Walk behind floor scrubber"
            judge="/v2-you-be-the-judge.pdf"
            brochure="https://www.dropbox.com/s/isekh8ont113wxr/FC-V2-WALK-BEHIND-SM.pdf?dl=0"
            techSpecs="/factorycat-micro-mini-mag-techspecs.pdf"
            partsManual="https://www.dropbox.com/s/hbsdmxvaqcb0ahr/FC-MAG-HD-v2-P-EN.pdf?dl=0"
            opsManual="https://www.dropbox.com/s/mnz7nzrpr9fa9bb/FC-walk-behind-v2-OP-EN.pdf?dl=0?dl=0"
          />
          <DownloadCard
            name="Pilot v2.1"
            image={PilotImage}
            alt="FactoryCat - Pilot Ride on floor scrubber"
            judge="/static/FC-RIDER-YBTJ-LANDSCAPE-d00922b575e0d94e4cb2d3b0590b49dd.pdf"
            brochure="https://www.dropbox.com/s/u77dwgm4t3xffk6/FC-V2-RIDERS-SM.pdf?dl=0?dl=0"
            techSpecs="/fatorycat-rider-v2-techspec.pdf"
            partsManual="/FC-PILOT-V2-1-P-EN.pdf"
            opsManual="/FC-RIDERS-V2-1-OP-EN.pdf"
          />
          <DownloadCard
            name="GTX v2.1"
            image={GTXImage}
            alt="FactoryCat - GTX v2.1 Ride on floor scrubber"
            judge="/static/FC-RIDER-YBTJ-LANDSCAPE-d00922b575e0d94e4cb2d3b0590b49dd.pdf"
            brochure="https://www.dropbox.com/s/u77dwgm4t3xffk6"
            techSpecs="/fatorycat-rider-v2-techspec.pdf"
            partsManual="/FC-GTX-V2-1-P-EN.pdf"
            opsManual="/FC-RIDERS-V2-1-OP-EN.pdf"
          />
          <DownloadCard
            name="GTR v2.1"
            image={GTRImage}
            alt="FactoryCat - GTR v2.1 Ride on floor scrubber"
            judge="/static/FC-RIDER-YBTJ-LANDSCAPE-d00922b575e0d94e4cb2d3b0590b49dd.pdf"
            brochure="https://www.dropbox.com/s/u77dwgm4t3xffk6"
            techSpecs="/fatorycat-rider-v2-techspec.pdf"
            partsManual="/FC-GTR-V2-1-P-EN.pdf"
            opsManual="/FC-RIDERS-V2-1-OP-EN.pdf"
          />
          <DownloadCard
            name="XR v2.0"
            image={XRImage}
            alt="FactoryCat - XR Ride on floor scrubber"
            judge="/static/FC-RIDER-YBTJ-LANDSCAPE-d00922b575e0d94e4cb2d3b0590b49dd.pdf"
            brochure="/FC-XR-V2-ONE-SHEET.pdf"
            techSpecs="/FC XR V20 TECH SPEC.pdf"
            partsManual="/FC-XR-V2-P-EN.pdf"
            opsManual="/FC-XR-V2-OP-EN.pdf"
          />
          <DownloadCard
            name="34"
            image={Sweeper34Image}
            alt="FactoryCat - 34 Walk behind floor sweeper"
            judge="/static/34-YBTJ-FC-f07a51ea657225a20b6485560c8545c1.pdf"
            brochure="https://www.dropbox.com/sh/qxwutzvg3snsvd7/AAAbRfBZ3Yijhf1noA_nAobJa/FC-34-TR-BROCHURE.pdf?dl=0"
            techSpecs="https://www.factorycat.com/docs/techSpecs/FC-34-TR-SALESSHEET.pdf"
            partsManual="https://www.dropbox.com/s/aj5r39gcrvv8tj6/FC-34-P-EN.pdf?dl=0"
            opsManual="https://www.factorycat.com/docs/manualsOp/FC-34-OP-EN.pdf"
          />
          <DownloadCard
            name="TR"
            image={TrImage}
            alt="FactoryCat - TR Ride on floor sweeper"
            judge="/static/TR-YBTJ-FC-2585fedb447f5a158f0bbaaea07f538e.pdf"
            brochure="https://www.dropbox.com/sh/qxwutzvg3snsvd7/AAAbRfBZ3Yijhf1noA_nAobJa/FC-34-TR-BROCHURE.pdf?dl=0"
            techSpecs="https://www.factorycat.com/docs/techSpecs/FC-34-TR-SALESSHEET.pdf"
            partsManual="https://www.dropbox.com/s/n0288r9jvaynjl7/FC-TR-P-EN.pdf?dl=0"
            opsManual="https://www.factorycat.com/docs/manualsOp/FC-TR-OP-EN.pdf"
          />
        </CardLayout>

        {selectValue === "micromag" && (
          <DownloadComponent
            name="MicroMag - Walk behind floor scrubber"
            image={MicroMagImage}
            alt="FactoryCat MicroMag Walk Behind Floor Scrubber"
            brochure=""
            techSpecs=""
            operatorManual=""
            partsManual=""
          />
        )}
        {selectValue === "micromini" && (
          <DownloadComponent
            name="MicroMini - Walk behind floor scrubber"
            image={MicroMiniImage}
            alt="FactoryCat MicroMini Walk Behind Floor Scrubber"
            brochure=""
            techSpecs=""
            operatorManual=""
            partsManual=""
          />
        )}
        {selectValue === "microhd" && (
          <DownloadComponent
            name="Micro-HD - Walk behind floor scrubber"
            image={MicroHDImage}
            alt="FactoryCat Micro-HD Walk Behind Floor Scrubber"
            brochure=""
            techSpecs=""
            operatorManual=""
            partsManual=""
          />
        )}
        {selectValue === "minihd" && (
          <DownloadComponent
            name="Mini-HD - Walk behind floor scrubber"
            image={MiniHDImage}
            alt="FactoryCat Mini-HD Walk Behind Floor Scrubber"
            brochure=""
            techSpecs=""
            operatorManual=""
            partsManual=""
          />
        )}
        {selectValue === "maghd" && (
          <DownloadComponent
            name="Mag-HD - Walk behind floor scrubber"
            image={MagHDImage}
            alt="FactoryCat Mag-HD Walk Behind Floor Scrubber"
            brochure=""
            techSpecs=""
            operatorManual=""
            partsManual=""
          />
        )}
        {selectValue === "pilot v2.1" && (
          <DownloadComponent
            name="Pilot v2.1- Ride on floor scrubber"
            image={PilotImage}
            alt="FactoryCat Pilot Ride on Floor Scrubber"
            brochure=""
            techSpecs=""
            operatorManual=""
            opsManual="/FC-RIDERS-V2-1-OP-EN.pdf"
            partsManual="/FC-PILOT-V2-1-P-EN.pdf"
          />
        )}
        {selectValue === "gtx v2.1" && (
          <DownloadComponent
            name="GTX v2.1- Ride on floor scrubber"
            image={GTXImage}
            alt="FactoryCat GTX Ride on Floor Scrubber"
            brochure=""
            techSpecs=""
            operatorManual=""
            opsManual="/FC-RIDERS-V2-1-OP-EN.pdf"
            partsManual="/FC-GTX-V2-1-P-EN.pdf"
          />
        )}
        {selectValue === "gtr v2.1" && (
          <DownloadComponent
            name="GTR v2.1 - Ride on floor scrubber"
            image={GTRImage}
            alt="FactoryCat GTR Ride on Floor Scrubber"
            brochure=""
            techSpecs=""
            operatorManual="/FC-RIDERS-V2-1-OP-EN.pdf"
            partsManual="/FC-GTR-V2-1-P-EN.pdf"
          />
        )}
        {selectValue === "xr" && (
          <DownloadComponent
            name="XR - Ride on floor scrubber"
            image={XRImage}
            alt="FactoryCat XR Ride on Floor Scrubber"
            brochure=""
            techSpecs=""
            operatorManual=""
            partsManual=""
          />
        )}
        {selectValue === "34" && (
          <DownloadComponent
            name="34 - Walk behind floor sweeper"
            image={Sweeper34Image}
            alt="FactoryCat 34 Walk behind floor sweeper"
            brochure=""
            techSpecs=""
            operatorManual=""
            partsManual=""
          />
        )}
        {selectValue === "tr" && (
          <DownloadComponent
            name="TR - Ride on floor sweeper"
            image={TrImage}
            alt="FactoryCat TR Ride on floor sweeper"
            brochure=""
            techSpecs=""
            operatorManual=""
            partsManual=""
          />
        )}
      </PageLayout>
    </Layout>
  )
}

export default ManualDownloads
