import React from "react"
import styled from "styled-components"

const CardsContainer = styled.div`
  border: 1px solid black;
  border-radius: 7px;
  padding: 0.5rem;
  display: flex;
  margin: 1rem;
`
const ImageContainer = styled.div`
  width: 250px;
  height: 250px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
const DownloadContainer = styled.div`
  p {
    text-decoration: none;
  }
  i {
    color: red;
  }
  a {
    text-decoration: none;
    color: black;
  }
`
const DownloadBar = styled.div`
  background-color: lightblue;
  border: 1px solid black;
  border-radius: 7px;
  padding: 0.2rem;
  width: 150px;
  margin: 3px auto;

  p {
    margin: 0;
  }
  /* padding: 0.1rem; */
`

const DownloadCard = props => {
  return (
    <CardsContainer>
      <ImageContainer>
        <img src={props.image} alt={props.alt} />
      </ImageContainer>
      <DownloadContainer>
        <h3>{props.name}</h3>
        <a href={props.judge} target="_blank">
          <DownloadBar>
            <p>
              <i className="fas fa-file-pdf" /> You be the Judge
            </p>
          </DownloadBar>
        </a>
        <a href={props.brochure} target="_blank">
          <DownloadBar>
            <p>
              <i className="fas fa-file-pdf" /> Brochure
            </p>
          </DownloadBar>
        </a>
        <a href={props.techSpecs} target="_blank">
          <DownloadBar>
            <p>
              <i className="fas fa-file-pdf" /> Tech Specs
            </p>
          </DownloadBar>
        </a>
        <a href={props.partsManual} target="_blank">
          <DownloadBar>
            <p>
              <i className="fas fa-file-pdf" /> Parts Manual
            </p>
          </DownloadBar>
        </a>
        <a href={props.opsManual} target="_blank">
          <DownloadBar>
            <p>
              <i className="fas fa-file-pdf" /> Operator Manual
            </p>
          </DownloadBar>
        </a>
      </DownloadContainer>
    </CardsContainer>
  )
}

export default DownloadCard
