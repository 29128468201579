import React from "react"
import styled from "styled-components"

const DownloadContainer = styled.div`
  display: flex;
  margin: 2rem auto;
`
const ImageContainer = styled.div`
  width: 350px;
  height: 350px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
const ManualContainer = styled.div`
  align-items: center;

  a {
    text-decoration: none;
    color: black;
  }

  #container {
    display: grid;
    height: 50%;
    grid-template-columns: 50% 50%;
  }
`
const PdfDownload = styled.div`
  margin: 7px;
  width: 300px;
  padding: 0.5rem;
  background-color: lightblue;
  border: 1px solid black;
  font-size: 1.1em;
  cursor: pointer;
  i {
    color: #f40f02;
  }
`

const DownloadComponent = props => {
  return (
    <DownloadContainer>
      <ImageContainer>
        <img src={props.image} alt={props.alt} />
      </ImageContainer>
      <ManualContainer>
        <h2>{props.name}</h2>
        <div id="container">
          <a href={props.brochure} target="_blank">
            <PdfDownload>
              <i className="fas fa-file-pdf" /> Brochure
            </PdfDownload>
          </a>
          <a href={props.techSpecs} target="_blank">
            <PdfDownload>
              <i className="fas fa-file-pdf" /> Tech Specs
            </PdfDownload>
          </a>

          <a href={props.operatorManual} target="_blank">
            <PdfDownload>
              <i className="fas fa-file-pdf" /> Operator Manual
            </PdfDownload>
          </a>
          <a href={props.partsManual} target="_blank">
            <PdfDownload>
              <i className="fas fa-file-pdf" /> Parts Manual
            </PdfDownload>
          </a>
        </div>
      </ManualContainer>
    </DownloadContainer>
  )
}
export default DownloadComponent
